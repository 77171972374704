import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/durchfall-bei-kindern"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337 active-trail active">
                      <a
                        href="/durchfall-bei-kindern/"
                        className="active-trail active"
                      >
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-bei-kindern"
                  className="block block-menu"
                >
                  <h2>Durchfall bei Kindern</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first last leaf active-trail">
                        <a
                          href="/durchfall-bei-kindern/"
                          title
                          className="active-trail active"
                        >
                          Durchfall bei Kindern
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-11" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/120228_imodium_anlan_07.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4
                        style={{
                          "font-size": "30px",
                          "line-height": "26px",
                          margin: "20px 0pt 10px",
                          "font-family": "'Open Sans',sans-serif"
                        }}
                      >
                        TIPPS BEI
                        <br />
                        DURCHFALL
                      </h4>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/teaser_durchfallmythen.jpg"
                          style={{
                            width: "174px",
                            height: "106px"
                          }}
                        />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Beim Thema Durchfall-
                        <br />
                        behandlung gibt es immer noch viele Unsicherheiten.
                        Lesen Sie hier einige Tipps.
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a
                            className="georgia arrow_01"
                            href="/durchfallmythen/"
                          >
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <div
                      about="/durchfall-bei-kindern"
                      typeof="sioc:Item foaf:Document"
                      className="ds-2col-stacked node node-simple-page node-promoted view-mode-full clearfix"
                    >
                      <div className="group-header">
                        <h1>Durchfall bei Kindern </h1>
                        <div className="headimg border">
                          <img
                            typeof="foaf:Image"
                            src="/sites/default/files/kinder.jpg"
                            width="695"
                            height="286"
                          />{" "}
                        </div>
                        <div className="field field-name-field-body-left field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <h3>Woher kommt Durchfall bei Kindern?</h3>
                              <p>
                                Die ersten drei Lebensjahre eines Kindes sind im
                                Durchschnitt von ein bis zwei Durchfallepisoden
                                pro Jahr begleitet. Meistens steckt eine
                                Viruserkrankung hinter dem wässrigen Stuhl.
                                Viruserkrankungen sind für knapp 70 Prozent
                                aller Durchfallerkrankungen in den frühen
                                Lebensjahren verantwortlich. Eine Impfung für
                                Kinder steht bisher nur gegen das Rotavirus zur
                                Verfügung. Rotaviren treten allerdings auch
                                besonders häufig auf. Eine andere Ursache für
                                Durchfall bei Kindern können{" "}
                                <a href="/nahrungsmittelallergie/">
                                  Nahrungsmittelunverträglichkeiten{" "}
                                </a>
                                sein.
                              </p>
                              <p>
                                Eine frühzeitige und dem Alter entsprechende
                                Behandlung ist bei Kindern sehr wichtig, egal
                                welche Ursache vorliegt. Bei Kindern droht
                                schneller als bei Erwachsenen eine Austrocknung.
                                Nicht nur Flüssigkeit geht dem Körper durch
                                Durchfall verloren, sondern auch
                                lebensnotwendige Elektrolyte.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="field field-name-field-body-right field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <p>
                                Die allerwichtigste Massnahme bei Säuglingen und
                                Kleinkindern ist daher der Flüssigkeitsersatz.
                                Bestehen Zeichen einer Dehydrierung
                                (Austrockung) muss das Kind sofort in ärztliche
                                Behandlung. Zeichen einer Austrocknung können
                                sein: geringe Urinmenge, dunkler Urin,
                                Antriebslosigkeit, keine Tränen beim Weinen,
                                eingefallene Augen.
                              </p>
                              <p>
                                Für Kinder ab 6 Jahren stehen neben dem
                                Flüssigkeitsersatz auch verschiedene rezeptfreie
                                Medikamente zur Behandlung des Durchfalls zur
                                Verfügung.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="std-site">
                        <div className="leftcol group-left"></div>
                        <div className="rightcol group-right"></div>
                      </div>
                      <div className="group-footer"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
